import React from "react";
import Artykul from "../components/artykul";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import obrazekArtykulu from "../images/zastepowalnosc-pracownika.png";
import Lightbox from "../components/lightBox";
import ResponsiveEmbed from "react-responsive-embed";
import { Link } from "gatsby";
import { LiteYouTubeEmbed } from "react-lite-youtube-embed";
import * as styles from "./index.module.css";


export const query = graphql`
  {
    kartoteka: file(relativePath: { eq: "kontrahenci-zdarzenia-questy.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    kanban: file(relativePath: { eq: "kanban-zlecenia-crm.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    kalendarz: file(
      relativePath: { eq: "kalendarz-zadan-pracownikow-crm.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    szanse: file(relativePath: { eq: "proces-szanse-sprzedazy-crm.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    zdarzenia: file(relativePath: { eq: "zdarzenia-handlowcow-crm.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

const Zastepowalnosc = ({ data }) => {
  return (
    <Artykul
      title="Zastępowalność pracowników, czyli jak zapewnić ciągłość pracy?"
      articleImage={obrazekArtykulu}
      articleImageAlt="Zastępowalność pracowników"
      metaTitle="Zastępowalność pracowników - jak zapenić ciągłość pracy?"
      metaDescription="Jak oprogramowanie CRM pomaga w zastępowalności pracowników? Sprawdź video!"
    >
      <p
        style={{
          paddingTop: 20,
        }}
      >
        Rotacja, a co za tym idzie potrzeba zastępowalności pracownika to
        wyzwanie dla wielu firm. Jak więc przygotować się do “zniknięcia”
        pracownika i zadbać o jego zastępstwo?
      </p>
      <p>
        Pandemia wprowadziła spore zmiany na rynku pracy. Wielu ludzi straciło
        zatrudnienie, przez co musieli oni odnaleźć dla siebie miejsce w innych
        dziedzinach gospodarki. Masowe przejście firm na tryb pracy zdalnej
        otworzyło z kolei szersze możliwości zatrudnienia, bez konieczności
        ograniczania się wyłącznie do danego terytorium.
      </p>
      <div className="text-block">
        <LiteYouTubeEmbed
          poster="maxresdefault"
          id="yQ6BSlB2JB0"
          title="Zastępowalność pracowników, czyli jak zadbać o brak przestojów w pracy"
        />
      </div>

      <h2
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        Jakie problemy niesie za sobą “zniknięcie” pracownika?
      </h2>
      <p>
        <ul>
          <li>
            <strong> utrata wiedzy </strong> - pracownik zabiera ze sobą dane
            kontaktowe, powiązania między partnerami a kontrahentami, ustalenia
            projektowe,
          </li>
          <li>
            <strong>
              {" "}
              brak informacji o zaplanowanych zadaniach, obiecanych tematach dla
              klientów{" "}
            </strong>{" "}
            - narażona jest więc jakość obsługi i relacje z kontrahentami,
          </li>
          <li>
            <strong> przestój w realizacji zadań </strong> - na danym
            stanowisku,
          </li>
          <li>
            <strong> czasochłonny proces rekrutacji</strong>,
          </li>
          <li>
            <strong> czasochłonny proces wdrożenia</strong>,
          </li>
          <li>
            <strong> koszt alternatywny </strong> - zadania, które mógł dalej
            realizować poprzedni pracownik oraz zadań, które mógłby zrealizować
            pracownik zajmujący się wdrażaniem nowej osoby,
          </li>
          <li>
            <strong> trudność w rozwijaniu nowych pracowników </strong> w
            przypadku pracy zdalnej lub w modelu hybrydowym,
          </li>
          <li>
            <strong> koszty administracyjne </strong> - związane z odejściem
            pracownika i zatrudnieniem nowej osoby.
          </li>
        </ul>
      </p>
      <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz poznać sposób na problem zastępowalności?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby zyskać dostęp do bezpłatnej 30-dniowej wersji systemu CRM

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i z sukcesem zarządzać rotacjami
            </Link>
          </div>
          </div>
    </section>
      <h2
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        Jak systemy informatyczne pomagają w zastępowalności pracowników?
      </h2>
      <p>
        <strong>CRM jest idealnym miejscem do planowania zadań</strong>.
        Współdzielone między pracownikami kalendarze ułatwiają codzienne
        planowanie wspólnych spotkań, ale także okazują się mocno pomocne w
        przypadku zastępstwa.
      </p>
      <Lightbox
        style={{
          maxWidth: 1000,
          margin: "0 auto",
        }}
        images={[data.kalendarz]}
        alts={["współdzielony kalendarz w crm - planowanie pracy zespołu"]}
      />
      <p>
        CRM to system,{" "}
        <strong> który porządkuje i usprawnia proces sprzedaży</strong>.
        Przechowywane są w nim dane także o potencjalnych klientach oraz
        transakcjach. Co w momencie, gdy odchodzi pracownik, który całą wiedzę i
        ustalenia z klientem zabiera ze sobą? CRM zabezpiecza Twoją firmę przed
        taką sytuacją, ponieważ możesz na bieżąco obserwować postępy w
        pozyskiwaniu klientów.
      </p>
      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.szanse]}
        alts={["zadania"]}
      />
      <p>
        Kartoteki kontrahentów w CRM gromadzą pełną historię kontaktów z
        klientami w jednym miejscu. Nowa osoba bardzo szybko pozna swojego
        klienta i będzie na bieżąco w Waszej współpracy. Dzięki temu zapobiegnie
        się zbędnemu przestojowi w pracy i będzie można natychmiast obsłużyć
        klienta.
      </p>
      <p>
        <Lightbox
          style={{
            maxWidth: 800,
            margin: "0 auto",
          }}
          images={[data.kartoteka]}
          alts={[
            "kartoteka kontrahentów w systemie crm - baza historii współpracy z klientem",
          ]}
        />
      </p>

      <p>
        Inne pliki firmowe takie jak umowy, czy projekty również możesz
        przechowywać w systemie CRM. Do tego zadania przewidziana jest specjalna
        powierzchnia, a mianowcie repozytorium plików, w którym każdy Twój dział
        może mieć własny folder do zamieszczania np. wzorów prezentacji lub
        firmowych ustaleń wewnętrznych
      </p>
      <p>
        <strong>
          W CRM-ie projekt rozpisywany jest na zlecenia ze szczegółowym opisem
          zaprezentowany w przystępny sposób np. na kanbanie
        </strong>
        . Możliwe jest także wygenerowanie wykresu gantta. Wystarczy chwila, aby
        zorientować się - co zostało wykonane, a co jest zaplanowane.
      </p>
      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.kanban]}
        alts={["wyświetlanie zleceń na kanbanie"]}
      />

      <p>
        Wdrożenie nowego pracownika wiąże się ze szkoleniem i obserwacją oraz
        wspieraniem w pierwszych krokach.{" "}
        <strong>
          Cały czas będziesz na bieżąco bez poświęcania całej uwagi tylko tej
          sprawie
        </strong>
        , ponieważ będziesz mógł obserwować wszystkie notatki z wykonanych zadań
        swoich podwładnych. Gdy zobaczysz jakąś nieprawidłowość jesteś w stanie
        szybko zareagować i przekazać feedback.
      </p>
      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.zdarzenia]}
        alts={["zdarzenia - czyli zarejestowane notatki handlowców"]}
      />
      <h2
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        Podsumowując korzyści wdrożenia CRM w kontekście zastępowalności
        pracowników:
      </h2>
      <p>
        <ul>
          <li>CRM zabezpiecza managerów przed negatywnymi skutkami rotacji,</li>
          <li>
            ułatwia zastępowalność pracownika podczas krótkich i długich
            urlopów,
          </li>
          <li>zdecydowanie przyspiesza proces wdrażania nowego pracownika,</li>
          <li>
            raportowanie umożliwia bieżące monitorowanie działań nowego
            pracownika i pomóc mu, gdy to jest konieczne,
          </li>
          <li>
            nie utracisz danych bo będą przechowywane w bezpiecznym miejscu - w
            systemie.
          </li>
        </ul>
      </p>
      <p
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        Niezależnie od tego czy przedsiębiorstwo jest na początku rozwoju czy ma
        już ugruntowaną pozycję na rynku, mając w firmie system CRM jako
        podstawowe narzędzie do zarządzania sprzedażą,{" "}
        <strong>
          zabezpieczasz ją przed negatywnymi skutkami potencjalnej rotacji
          pracowników
        </strong>
        .
      </p>
      <p
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
      <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz poznać sposób na problem zastępowalności?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby zyskać dostęp do bezpłatnej 30-dniowej wersji systemu CRM

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i z sukcesem zarządzać rotacjami
            </Link>
          </div>
          </div>
    </section>
        <strong> Dowiedz się więcej:</strong>
        <ul>
          <li>
            <Link to="/co-to-jest-crm/"> Co to jest CRM?</Link>
          </li>
          <li>
            <Link to="/ofertowanie-crm-jak-zarzadzac/">
              {" "}
              Ofertowanie w CRM: jak zarządzać tym procesem?{" "}
            </Link>
          </li>
          <li>
            <Link to="/wdrazanie-kultury-pracy-CRM/">
              {" "}
              Wdrażanie kultury pracy z CRM w organizacji
            </Link>
          </li>
        </ul>
      </p>
    </Artykul>
  );
};

export default Zastepowalnosc;
